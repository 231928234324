<template>
  <base-button
    class="flex justify-center items-center font-normal rounded-sm focus:outline-none text-sm leading-4"
    type="button"
    size="p-1"
    :class="active ? activeBtnClass : inactiveBtnClass"
    :disabled="isLoading || !active"
    disabled-class="cursor-not-allowed opacity-60"
    @click="handleClick($event)"
  >
    <base-svg
      class="h-4 w-4 inline-block"
      :class="active ? activeIconClass : inactiveIconClass"
      :src="icon"
      :svg-attributes="{
        class: 'h-full w-full'
      }"
      tag="span"
    />
  </base-button>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'IconButton',

    components: {
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    props: {
        icon: {
            type: String,
            default: '',
            description: 'icon path passed to src of base-svg'
        },
        active: {
            type: Boolean,
            default: false,
            description: 'triggers active state'
        },
        activeBtnClass: {
            type: String,
            default: '',
            description: 'classes applied to button when it is active'
        },
        activeIconClass: {
            type: String,
            default: 'text-primary-red',
            description: 'classes applied to icon when button is active'
        },
        inactiveBtnClass: {
            type: String,
            default: 'cursor cursor-not-allowed bg-custom-gray-2',
            description: 'classes applied to button when it is inactive'
        },
        inactiveIconClass: {
            type: String,
            default: 'text-custom-gray-3',
            description: 'classes applied to icon when button is inactive'
        },
        isLoading: {
            type: Boolean,
            default: false,
            description: 'toggles button to loading state'
        }
    },

    emits: [
        'click'
    ],

    setup (props, { emit }) {
        const handleClick = (eventPayload) => {
            if (props.active) {
                emit('click', eventPayload);
            }
        };

        return {
            handleClick
        };
    }
};
</script>
